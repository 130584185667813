import React, { useState, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormGroup,
  Button,
  Label,
} from "reactstrap";
import login_logo from "../imgs/login.png";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { ForgotPassword } from "../components";
import { login } from "../redux/actions";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { toast } from "react-toastify";
import { SignupContext } from "./SignupContext";
import { config } from "../common";

const loginSchema = yup.object().shape({
  email: yup.string().required("Email id is a required field."),
  password: yup.string().required("Password is a required field."),
});

const Login = ({ modalLogin, setModalLogin, toggleLogin }) => {
  const { control, register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { setModalSignUp, setSignData } = useContext(SignupContext);

  const [modalForgot, setModalForgot] = useState(false);

  const toggleForgot = () => setModalForgot(!modalForgot);

  const dispatch = useDispatch();
  const history = useHistory();

  const responseSuccess = (response) => {
    if (response.profileObj.googleId && response.profileObj.googleId != "") {
      var formData = new FormData();
      formData.append("email", response.profileObj.email);
      formData.append("is_google", 1);
      formData.append("google_id", response.profileObj.googleId);

      axios
        .post(`${config.apiUrl}/is_register`, formData, {
          headers: {
            key: "351292hb22fe342a41370e16c1510ab9867031516mz1209",
          },
        })
        .then((res) => {
          // console.log(res);
          //console.log(res.data.ResponseCode);
          console.log("=================================", res);
          console.log("=================================", res.data);
          if (res.data.ResponseCode == 3) {
            console.log("33333333333333333--------", res.data.ResponseCode);
            setSignData({
              userEmail: response.profileObj.email,
              userName: response.profileObj.name,
              isManual: 0,
              is_google: 1,
              google_id: response.profileObj.googleId,
            });
            console.log(
              "22222222222222222222222--------",
              res.data.ResponseCode
            );
            setModalLogin(!modalLogin);
            console.log("545454445444--------", res.data.ResponseCode);
            setModalSignUp(true);
          } else if (
            res.data.ResponseCode == 1 ||
            res.data.ResponseCode == "1"
          ) {
            localStorage.setItem("token", res.data.data.generate_token);
            localStorage.setItem("userId", res.data.data.id);
            localStorage.setItem("user_type", res.data.data.user_type);
            localStorage.setItem("user", JSON.stringify(res.data.data));
            setModalLogin(!modalLogin);
            dispatch({ type: "LOGIN_FETCH_SUCCESS" });

            toast.success(res.data.ResponseMsg);
            history.push("/");
          } else {
            toast.error(res.data.ResponseMsg);
            dispatch({ type: "LOGIN_FETCH_FAILURE" });
          }
        })
        .catch((error) => {
          toast.error(error);
          // toast.error(error.data.message);
        });
    }
  };
  const responseFailure = (response) => {
    toast.error(response);
  };

  const responseFacebook = (response) => {
    //console.log(response);

    if (response.email && response.email != "") {
      var formData = new FormData();

      formData.append("email", response.email);
      formData.append("is_fb", 1);
      formData.append("fb_id", response.id);

      axios
        .post(`${config.apiUrl}/is_register`, formData, {
          headers: {
            key: "351292hb22fe342a41370e16c1510ab9867031516mz1209",
          },
        })
        .then((res) => {
          // console.log(res);

          if (res.data.ResponseCode == 3) {
            setSignData({
              userEmail:
                response.email && response.email != ""
                  ? response.email
                  : undefined,
              userName: response.name,
              isManual: response.email && response.email != "" ? 0 : 1,
              is_fb: 1,
              fb_id: response.id,
            });
            setModalLogin(!modalLogin);
            setModalSignUp(true);
          } else if (res.data.ResponseCode == 1) {
            localStorage.setItem("token", res.data.Result.generate_token);
            localStorage.setItem("userId", res.data.Result.id);

            dispatch({ type: "LOGIN_FETCH_SUCCESS" });
            setModalLogin(!modalLogin);

            toast.success(res.data.ResponseMsg);
            history.push("/");
          } else {
            dispatch({ type: "LOGIN_FETCH_FAILURE" });
            toast.error(res.data.ResponseMsg);
          }
        })
        .catch((error) => {
          toast.error(error);
          // toast.error(error.data.message);
        });
    }
  };

  const componentClicked = () => {
    console.log(".");
  };

  const onSubmit = (data) => {
    dispatch(login(data, history, setModalLogin));
    toggleLogin();
  };
  return (
    <div>
      <Modal size="lg" isOpen={modalLogin}>
        <ModalHeader toggle={toggleLogin} className="how-to-header">
          <img src={login_logo} alt="login logo" className="mr-3" />
          <span className="log-in-title">LOG IN</span>
        </ModalHeader>

        <ModalBody>
          <Form className="form__padder" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <FormGroup>
                <Label className="property-from-label">Email</Label>
                <Controller
                  as={Input}
                  id="user"
                  control={control}
                  name="email"
                  type="text"
                  placeholder="Email Id"
                  defaultValue=""
                  ref={register}
                  className="login-inputs"
                />
                {errors && errors.email && (
                  <span className="text-danger">{errors.email.message}</span>
                )}
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Label className="property-from-label">Password</Label>
                <Controller
                  as={Input}
                  id="pwd"
                  control={control}
                  name="password"
                  type="password"
                  placeholder="Password"
                  defaultValue=""
                  ref={register}
                  className="login-inputs"
                />
                {errors && errors.password && (
                  <span className="text-danger">{errors.password.message}</span>
                )}
              </FormGroup>
            </div>

            <div className="form-row">
              <div className="col-sm-6">
                <input type="checkbox" className="rememberme-checkbox" />
                <span className="remember-me">Remember me</span>
              </div>
              <div className="col-sm-6">
                <div className="forgot-password" onClick={toggleForgot}>
                  Forgot your password?
                </div>
              </div>
            </div>

            <div>
              <Button type="submit" className="log-in-button">
                LOGIN
              </Button>
            </div>
          </Form>
          <div className="form__padder">
            <div className="or-line ">
              <span className="or-text">or</span>
            </div>
          </div>
          <div className="row form__padder pb-4">
            <div className="col-sm-6">
              {/* ghanshyam.kmphitech@gmail.com use karelu che */}
              <button className="google-btn">
                <GoogleLogin
                  clientId={config.GOOGLEAPIKEY}
                  buttonText="Continue With Google"
                  onSuccess={responseSuccess}
                  onFailure={responseFailure}
                />
                <FcGoogle className="google-icon" />
                Continue With Google
              </button>
            </div>
            <div className="col-sm-6">
              {/* ghanshyam.kmphitech@gmail.com use karelu che fbid for testing  586937182741496 */}
              <button className="facebook-btn">
                <FacebookLogin
                  appId={config.FBAPPID}
                  autoLoad={false}
                  fields="name,email,picture"
                  onClick={componentClicked}
                  callback={responseFacebook}
                />
                <FaFacebookF className="google-icon" />
                Continue With Facebook
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {modalForgot && (
        <ForgotPassword
          modalForgot={modalForgot}
          setModalForgot={setModalForgot}
          toggleForgot={toggleForgot}
        />
      )}
    </div>
  );
};

export default Login;
